import React, { useMemo, useState } from 'react';
import {
  Button,
  Dropdown,
  Header,
  HeaderGlobalAction,
  HeaderGlobalBar,
  HeaderMenuItem,
  HeaderName,
  HeaderNavigation,
  SideNav,
  SideNavItems,
  SideNavLink,
} from '@carbon/react';
import {
  FaCode,
  FaCopy,
  FaCog,
  FaFileInvoiceDollar,
  FaBuilding,
  FaMoneyBill,
  FaPiggyBank,
  FaPlus,
  FaTrash,
} from 'react-icons/fa';

import {
  Budgets,
  Cashflow,
  ConfirmModal,
  ExportImportDataModal,
  Loans,
  Logo,
  Properties,
  SettingsModal,
} from './components';

import './App.scss';
import { MAX_PROJECTIONS, useGlobalState } from './hooks/useGlobalState';

export const AppSection = {
  Cashflow: 'cashflow',
  Personal: 'personal',
  Loans: 'loans',
  Properties: 'properties',
};

export default function App() {
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [isExportImportDataVisible, setIsExportImportDataVisible] =
    useState(false);
  const [isDeletingProjection, setIsDeletingProjection] = useState(false);
  const [selectedSection, setSelectedSection] = useState(AppSection.Cashflow);
  const {
    isInit,
    yearIndex,
    saveYearIndex,
    settings,
    allLoansData,
    addProjection,
    duplicateSelectedProjection,
    deleteSelectedProjection,
    selectedProjectionIndex,
    setSelectedProjectionIndex,
    exportAllData,
    importAllData,
  } = useGlobalState();

  const yearOptions = useMemo(() => {
    const options = [];
    for (let i = 0; i < ((settings || []).projectionLength || 35); i++) {
      options.push({
        id: i,
        text: `Year ${i + 1}`,
      });
    }
    return options;
  }, [settings]);

  if (!isInit) return null;

  return (
    <div className="App">
      <Header aria-label="FutureFunds">
        <HeaderName prefix="">
          <Logo />
        </HeaderName>
        <HeaderNavigation aria-label="FutureFunds">
          {allLoansData.map((_, index) => (
            <HeaderMenuItem
              key={index}
              isActive={index === selectedProjectionIndex}
              onClick={() => setSelectedProjectionIndex(index)}
            >
              Projection {index + 1}
            </HeaderMenuItem>
          ))}
          {allLoansData.length < MAX_PROJECTIONS && (
            <Button hasIconOnly onClick={() => addProjection()}>
              <FaPlus />
            </Button>
          )}
        </HeaderNavigation>
        <HeaderGlobalBar>
          <Dropdown
            id="yearIndex"
            label="Select year"
            items={yearOptions}
            itemToString={(item) => (item ? item.text : '')}
            selectedItem={yearOptions.find((o) => o.id === yearIndex)}
            onChange={({ selectedItem }) => saveYearIndex(selectedItem.id)}
          />
          <HeaderGlobalAction
            aria-label="Export / import data"
            onClick={() => setIsExportImportDataVisible(true)}
          >
            <FaCode />
          </HeaderGlobalAction>
          <HeaderGlobalAction
            aria-label="Search"
            onClick={() => setIsSettingsVisible(true)}
          >
            <FaCog />
          </HeaderGlobalAction>
        </HeaderGlobalBar>
        <SideNav aria-label="Side navigation" expanded href="#main-content">
          <SideNavItems>
            <SideNavLink
              large
              renderIcon={FaMoneyBill}
              isActive={selectedSection === AppSection.Cashflow}
              onClick={() => setSelectedSection(AppSection.Cashflow)}
            >
              Cashflow
            </SideNavLink>
            <SideNavLink
              large
              renderIcon={FaPiggyBank}
              isActive={selectedSection === AppSection.Personal}
              onClick={() => setSelectedSection(AppSection.Personal)}
            >
              Personal
            </SideNavLink>
            <SideNavLink
              large
              renderIcon={FaFileInvoiceDollar}
              isActive={selectedSection === AppSection.Loans}
              onClick={() => setSelectedSection(AppSection.Loans)}
            >
              Loans
            </SideNavLink>
            <SideNavLink
              large
              renderIcon={FaBuilding}
              isActive={selectedSection === AppSection.Properties}
              onClick={() => setSelectedSection(AppSection.Properties)}
            >
              Properties
            </SideNavLink>
          </SideNavItems>
          <div className="App_ActionButtons">
            {allLoansData.length < MAX_PROJECTIONS && (
              <Button
                renderIcon={FaCopy}
                kind="secondary"
                onClick={() => duplicateSelectedProjection()}
              >
                Duplicate projection
              </Button>
            )}
            {allLoansData.length > 1 && (
              <Button
                renderIcon={FaTrash}
                kind="danger"
                onClick={() => setIsDeletingProjection(true)}
              >
                Delete projection
              </Button>
            )}
          </div>
        </SideNav>
      </Header>
      <div className="App_Content">
        {selectedSection === AppSection.Cashflow && <Cashflow />}
        {selectedSection === AppSection.Personal && <Budgets />}
        {selectedSection === AppSection.Loans && <Loans />}
        {selectedSection === AppSection.Properties && <Properties />}
      </div>
      {isSettingsVisible && (
        <SettingsModal open onClose={() => setIsSettingsVisible(false)} />
      )}
      {isExportImportDataVisible && (
        <ExportImportDataModal
          open
          data={exportAllData()}
          onSave={(data) => {
            importAllData(data);
            setIsExportImportDataVisible(false);
          }}
          onClose={() => setIsExportImportDataVisible(false)}
        />
      )}
      {isDeletingProjection && (
        <ConfirmModal
          open
          danger
          title={`Delete projection`}
          message={`Are you sure you want to delete "Projection ${
            selectedProjectionIndex + 1
          }"?`}
          data={selectedProjectionIndex}
          confirmLabel="Delete"
          onConfirm={() => {
            deleteSelectedProjection();
            setIsDeletingProjection(false);
          }}
          onCancel={() => setIsDeletingProjection(false)}
        />
      )}
    </div>
  );
}
