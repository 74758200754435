import React, { memo, useState } from 'react';
import { Modal, NumberInput, Slider } from '@carbon/react';

import { useGlobalState } from '../../hooks/useGlobalState';
import { getEventTargetAttr } from '../../utils/events';

import './SettingsModal.scss';

function SettingsModal({ open, onClose }) {
  const { settings, saveSettings } = useGlobalState();
  const [data, setData] = useState(settings);

  function handleSave() {
    saveSettings(data);
    onClose();
  }

  function updateProjectionLength({ value }) {
    if (typeof value !== 'undefined') {
      setData({
        ...data,
        projectionLength: +value,
      });
    }
  }

  return (
    <Modal
      open={open}
      modalHeading="Settings"
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      onRequestSubmit={handleSave}
      onRequestClose={onClose}
      onSecondarySubmit={onClose}
    >
      <div className="SettingsModalContent">
        <Slider
          labelText="Projection length (years)"
          value={+data.projectionLength}
          min={1}
          max={35}
          step={1}
          stepMultiplier={1}
          noValidate
          onBlur={updateProjectionLength}
          onChange={updateProjectionLength}
        />
        <div className="ModalContent_TwoColumn">
          <NumberInput
            id="inflation"
            label="Inflation rate (%)"
            defaultValue={data.inflation}
            value={data.inflation}
            onChange={(event) => {
              setData({
                ...data,
                [getEventTargetAttr(event, 'id')]:
                  Math.round((getEventTargetAttr(event, 'value') || 0) * 100) /
                  100,
              });
            }}
            step={0.1}
          />
          <NumberInput
            id="incomeGrowth"
            label="Income growth rate (%)"
            defaultValue={data.incomeGrowth}
            value={data.incomeGrowth}
            onChange={(event) => {
              setData({
                ...data,
                [getEventTargetAttr(event, 'id')]:
                  Math.round((getEventTargetAttr(event, 'value') || 0) * 100) /
                  100,
              });
            }}
            step={0.1}
          />
        </div>
      </div>
    </Modal>
  );
}

export default memo(SettingsModal);
