import { Modal } from '@carbon/react';
import React, { memo } from 'react';

import './ConfirmModal.scss';

function ConfirmModal({
  title = 'Confirm modal',
  message = 'Are you sure?',
  danger,
  open,
  data,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  onConfirm,
  onCancel,
}) {
  return (
    <Modal
      open={open}
      danger={danger}
      modalHeading={title}
      primaryButtonText={confirmLabel}
      secondaryButtonText={cancelLabel}
      onRequestSubmit={() => onConfirm(data)}
      onRequestClose={onCancel}
      onSecondarySubmit={onCancel}
    >
      <div className="ConfirmModalContent">{message}</div>
    </Modal>
  );
}

export default memo(ConfirmModal);
