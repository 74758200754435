import React, { memo, useMemo, useState } from 'react';

import { useGlobalState } from '../../hooks/useGlobalState';

import LoansChart from './LoansChart';
import LoanModal, { LoanModalMode } from './LoanModal';
import LoansTable from './LoansTable';

import './Loans.scss';
import ConfirmModal from '../ConfirmModal';

function Loans() {
  const [selectedLoan, setSelectedLoan] = useState(undefined);
  const [loanToDelete, setLoanToDelete] = useState(undefined);
  const {
    getLoansData,
    saveLoan,
    duplicateLoan,
    deleteLoan,
  } = useGlobalState();
  const loans = useMemo(() => getLoansData(), [getLoansData]);

  return (
    <div className="Loans">
      <LoansTable
        rows={loans}
        onRowClick={(loanId) =>
          setSelectedLoan(loans.find((loan) => loan.id === loanId))
        }
        onAddClick={() => setSelectedLoan({})}
        onDuplicateClick={(loanId) => duplicateLoan(loanId)}
        onDeleteClick={(loanId) =>
          setLoanToDelete(loans.find((loan) => loan.id === loanId))
        }
      />
      <LoansChart loans={loans} />
      {Boolean(selectedLoan) && (
        <LoanModal
          open
          mode={
            selectedLoan && selectedLoan.id
              ? LoanModalMode.Edit
              : LoanModalMode.Add
          }
          data={selectedLoan}
          onSave={(loan) => {
            saveLoan(loan);
            setSelectedLoan(undefined);
          }}
          onCancel={() => setSelectedLoan(undefined)}
        />
      )}
      {Boolean(loanToDelete) && (
        <ConfirmModal
          open
          danger
          title={`Delete loan`}
          message={`Are you sure you want to delete "${loanToDelete.name}"?`}
          data={loanToDelete}
          confirmLabel="Delete"
          onConfirm={() => {
            deleteLoan(loanToDelete.id);
            setLoanToDelete(undefined);
          }}
          onCancel={() => setLoanToDelete(undefined)}
        />
      )}
    </div>
  );
}

export default memo(Loans);
