import React, { memo, useMemo, useState } from 'react';
import {
  Button,
  ButtonSet,
  Modal,
  NumberInput,
  TextInput,
} from '@carbon/react';
import { v4 as uuid } from 'uuid';
import { FaPlus, FaMinus } from 'react-icons/fa';

import { PaymentFrequency } from '../../utils/constants';
import { getEventTargetAttr } from '../../utils/events';

import './PropertyCostsModal.scss';

const headers = ['Name', 'Frequency', 'Amount ($)', ''];

function PropertyCostsModal({ data: initialData, open, onSave, onCancel }) {
  const [data, setData] = useState([
    ...(Boolean(initialData) && initialData.length > 0
      ? initialData
      : [createNewRowData()]),
  ]);

  const frequencyOptions = useMemo(
    () => [
      { id: PaymentFrequency.Weekly, text: 'Wk' },
      { id: PaymentFrequency.Monthly, text: 'Mth' },
      { id: PaymentFrequency.Quarterly, text: 'Qtr' },
      { id: PaymentFrequency.Annually, text: 'Yr' },
    ],
    []
  );

  function handleTextInputChange(rowIndex, event) {
    const rowData = {
      ...data[rowIndex],
      [getEventTargetAttr(event, 'id')]: getEventTargetAttr(event, 'value'),
    };
    const updatedData = [...data];
    updatedData.splice(rowIndex, 1, rowData);
    setData(updatedData);
  }

  function handleNumberInputChange(rowIndex, event) {
    const rowData = {
      ...data[rowIndex],
      [getEventTargetAttr(event, 'id')]: +(
        getEventTargetAttr(event, 'value') || 0
      ),
    };
    const updatedData = [...data];
    updatedData.splice(rowIndex, 1, rowData);
    setData(updatedData);
  }

  function createNewRowData() {
    return {
      id: uuid(),
      name: '',
      frequency: PaymentFrequency.Monthly,
      amount: 0,
    };
  }

  function handleAddRow(rowIndex) {
    const rowData = createNewRowData();
    const updatedData = [...data];
    updatedData.splice(rowIndex + 1, 0, rowData);
    setData(updatedData);
  }

  function handleDeleteRow(rowIndex) {
    const updatedData = [...data];
    updatedData.splice(rowIndex, 1);
    if (updatedData.length === 0) {
      setData([createNewRowData()]);
    } else {
      setData(updatedData);
    }
  }

  function handleSave() {
    onSave(data.filter((row) => Boolean((row.name || '').trim())));
  }

  return (
    <Modal
      open={open}
      className="PropertyCostsModal"
      modalHeading="Property costs"
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      onRequestSubmit={handleSave}
      onRequestClose={onCancel}
      onSecondarySubmit={onCancel}
    >
      <div className="PropertyCostsModalContent">
        <div className="PropertyCosts_ListHeader">
          {headers.map((header) => (
            <div id={header.key} key={header}>
              {header}
            </div>
          ))}
        </div>
        {data.map((row, rowIndex) => (
          <div className="PropertyCosts_ListRow" key={row.id}>
            {Object.keys(row)
              .filter((key) => key !== 'id')
              .map((key) => {
                switch (key) {
                  case 'name':
                    return (
                      <div key={key}>
                        <TextInput
                          id={key}
                          autoFocus
                          defaultValue={row[key]}
                          onChange={(event) =>
                            handleTextInputChange(rowIndex, event)
                          }
                        />
                      </div>
                    );

                  case 'amount':
                    return (
                      <div key={key}>
                        <NumberInput
                          id={key}
                          defaultValue={row[key]}
                          onChange={(event) =>
                            handleNumberInputChange(rowIndex, event)
                          }
                          hideSteppers
                        />
                      </div>
                    );

                  case 'frequency':
                    return (
                      <div key={key}>
                        <ButtonSet className="PropertyCosts_FrequencyOptions">
                          {frequencyOptions.map((option) => (
                            <Button
                              key={option.id}
                              kind={
                                row.frequency === option.id
                                  ? 'primary'
                                  : 'secondary'
                              }
                              onClick={() =>
                                handleTextInputChange(rowIndex, {
                                  target: { id: key, value: option.id },
                                })
                              }
                            >
                              {option.text}
                            </Button>
                          ))}
                        </ButtonSet>
                      </div>
                    );

                  default:
                    return <div key={key}>{row[key]}</div>;
                }
              })}
            <div key="status" className="PropertyCosts_ListActions">
              <Button
                hasIconOnly
                renderIcon={FaPlus}
                kind="ghost"
                iconDescription="Add row below"
                onClick={() => handleAddRow(rowIndex)}
              />
              <Button
                hasIconOnly
                renderIcon={FaMinus}
                kind="danger--ghost"
                iconDescription="Remove row"
                onClick={() => handleDeleteRow(rowIndex)}
              />
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
}

export default memo(PropertyCostsModal);
