import numeral from 'numeral';

export function formatCurrency(value) {
  return numeral(value).format('$0,0.00');
}

export function formatPercentage(value) {
  return numeral(value / 100).format('0.00%');
}

export function getSentimentColor(value) {
  return value > 0 ? 'PositiveColor' : value < 0 ? 'NegativeColor' : '';
}
