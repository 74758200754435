import React, { memo, useMemo } from 'react';

import { useGlobalState } from '../../hooks/useGlobalState';

import CashflowChart from './CashflowChart';
import CashflowTable from './CashflowTable';

import './Cashflow.scss';

function Cashflow() {
  const { getBudgetsData, getLoansData, getPropertiesData } = useGlobalState();
  const data = useMemo(
    () => ({
      budgets: getBudgetsData(),
      loans: getLoansData(),
      properties: getPropertiesData(),
    }),
    [getBudgetsData, getLoansData, getPropertiesData],
  );

  return (
    <div className="Cashflow">
      <CashflowTable data={data} />
      <CashflowChart data={data} />
    </div>
  );
}

export default memo(Cashflow);
