import React, { memo, useMemo } from 'react';

import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  Button,
  Link,
} from '@carbon/react';
import { FaCopy, FaTrash } from 'react-icons/fa';

import { PaymentFrequency } from '../../utils/constants';
import {
  formatCurrency,
  formatPercentage,
  getSentimentColor,
} from '../../utils/format';
import {
  compoundPropertyMonthlyCostSumOf,
  projectProperty,
  projectPropertyRow,
} from '../../utils/projection';

import { PropertyType } from './PropertyModal';
import { useGlobalState } from '../../hooks/useGlobalState';
import InfoGroup from '../InfoGroup/InfoGroup';
import { toMonthlyValue } from '../../utils/math';

const headers = [
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'propertyType',
    header: 'Property type',
  },
  {
    key: 'value',
    header: 'Puchase value ($)',
    align: 'right',
  },
  {
    key: 'capitalGrowth',
    header: 'Capital growth (%)',
    align: 'right',
  },
  {
    key: 'capitalGrowthValue',
    header: 'Capital growth ($)',
    align: 'right',
  },
  {
    key: 'rentalGrowth',
    header: 'Rental growth (%)',
    align: 'right',
  },
  {
    key: 'rental',
    header: 'Weekly rental ($)',
    align: 'right',
  },
  {
    key: 'costs',
    header: 'Monthly costs ($)',
    align: 'right',
  },
  {
    key: 'cashFlow',
    header: 'Cashflow ($)',
    align: 'right',
  },
];

function PropertiesTable({
  rows,
  onRowClick,
  onAddClick,
  onDuplicateClick,
  onDeleteClick,
  onRowCostsClick,
}) {
  const { settings, yearIndex } = useGlobalState();
  const projections = useMemo(
    () =>
      (rows || []).map((row) =>
        projectProperty(row, settings.projectionLength),
      ),
    [rows, settings.projectionLength],
  );

  const rowProjections = useMemo(
    () =>
      (rows || []).map((row) =>
        projectPropertyRow(row, settings.projectionLength),
      ),
    [rows, settings.projectionLength],
  );

  const description = useMemo(() => {
    let totalCapitalGrowth = 0;
    let totalRentalGrowth = 0;
    projections.forEach((projection) => {
      totalCapitalGrowth +=
        projection[projection.length - 1].value - projection[0].value;
      totalRentalGrowth +=
        projection[projection.length - 1].rental - projection[0].rental;
    });

    let totalMonthlyIncome = 0;
    let totalMonthlyCosts = 0;
    let totalMonthlyCashflow = 0;
    (rows || []).forEach((row, rowIndex) => {
      const rowProjection = rowProjections[rowIndex][yearIndex];
      totalMonthlyIncome +=
        row.propertyType === PropertyType.Investment
          ? toMonthlyValue(rowProjection.rental, PaymentFrequency.Weekly)
          : 0;
      totalMonthlyCosts += compoundPropertyMonthlyCostSumOf(
        row,
        settings.inflation,
        yearIndex,
      );
    });
    totalMonthlyCashflow = totalMonthlyIncome - totalMonthlyCosts;
    return (
      <InfoGroup
        data={[
          {
            label: 'Total capital growth',
            value: formatCurrency(totalCapitalGrowth),
          },
          {
            label: 'Total rental growth',
            value: formatCurrency(totalRentalGrowth),
          },
          {
            label: 'Monthly income',
            value: formatCurrency(totalMonthlyIncome),
          },
          {
            label: 'Monthly cost',
            value: formatCurrency(totalMonthlyCosts),
          },
          {
            label: 'Monthly cashflow',
            value: (
              <span className={getSentimentColor(totalMonthlyCashflow)}>
                {formatCurrency(totalMonthlyCashflow)}
              </span>
            ),
          },
        ]}
      />
    );
  }, [projections, rows, rowProjections, yearIndex, settings.inflation]);

  const formattedRows = useMemo(
    () =>
      (rows || []).map((row, index) => {
        const rowProjection = rowProjections[index][yearIndex];
        const monthlyCosts = compoundPropertyMonthlyCostSumOf(
          row,
          settings.inflation,
          yearIndex,
        );
        const monthlyRentalIncome =
          row.propertyType === PropertyType.OwnerOccupied
            ? 0
            : (rowProjection.rental * 52) / 12;
        const cashFlow = monthlyRentalIncome - monthlyCosts;
        return {
          ...row,
          propertyType:
            row.propertyType === PropertyType.OwnerOccupied
              ? 'Owner occupied'
              : 'Investment',
          value: formatCurrency(row.value),
          capitalGrowth: formatPercentage(row.capitalGrowth),
          capitalGrowthValue: formatCurrency(rowProjection.capitalGrowth),
          rental:
            row.propertyType === PropertyType.Investment
              ? formatCurrency(rowProjection.rental)
              : '--',
          rentalGrowth:
            row.propertyType === PropertyType.Investment
              ? formatPercentage(row.rentalGrowth)
              : '--',
          costs: (
            <Link
              onClick={(event) => {
                event.stopPropagation();
                onRowCostsClick(row.id);
              }}
            >
              {formatCurrency(monthlyCosts)}
            </Link>
          ),
          cashFlow: (
            <div className={getSentimentColor(cashFlow)}>
              {formatCurrency(cashFlow)}
            </div>
          ),
        };
      }),
    [rows, onRowCostsClick, rowProjections, yearIndex, settings.inflation],
  );

  function handleDuplicateRow(row, event) {
    event.preventDefault();
    event.stopPropagation();
    onDuplicateClick(row);
  }

  function handleDeleteRow(row, event) {
    event.preventDefault();
    event.stopPropagation();
    onDeleteClick(row);
  }

  return (
    <DataTable rows={formattedRows} headers={headers}>
      {({
        rows,
        headers,
        getTableProps,
        getHeaderProps,
        getRowProps,
        getToolbarProps,
        getTableContainerProps,
      }) => (
        <TableContainer
          title={`Properties summary for ${settings.projectionLength} ${
            settings.projectionLength <= 1 ? 'year' : 'years'
          }`}
          description={description}
          {...getTableContainerProps()}
        >
          <TableToolbar
            {...getToolbarProps()}
            aria-label="Properties table toolbar"
          >
            <TableToolbarContent>
              <Button onClick={onAddClick}>Add new</Button>
            </TableToolbarContent>
          </TableToolbar>
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader
                    {...getHeaderProps({ header })}
                    align={header.align}
                  >
                    {header.header}
                  </TableHeader>
                ))}
                <TableHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  {...getRowProps({ row })}
                  onClick={() => onRowClick(row.id)}
                >
                  {row.cells.map((cell, index) => (
                    <TableCell key={cell.id} align={headers[index].align}>
                      {cell.value}
                    </TableCell>
                  ))}
                  <TableCell
                    key="actions"
                    align={headers[headers.length - 1].align}
                  >
                    <div key="status" className="TableRowActions">
                      <Button
                        hasIconOnly
                        renderIcon={FaCopy}
                        kind="ghost"
                        iconDescription="Duplicate"
                        onClick={(event) => handleDuplicateRow(row.id, event)}
                      />
                      <Button
                        hasIconOnly
                        renderIcon={FaTrash}
                        kind="danger--ghost"
                        iconDescription="Delete"
                        onClick={(event) => handleDeleteRow(row.id, event)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  );
}

export default memo(PropertiesTable);
