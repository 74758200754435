import React, { memo, useMemo, useState } from 'react';

import { useGlobalState } from '../../hooks/useGlobalState';

import BudgetsChart from './BudgetsChart';
import BudgetModal, { BudgetModalMode } from './BudgetModal';
import BudgetsTable from './BudgetsTable';

import './Budgets.scss';
import ConfirmModal from '../ConfirmModal';

function Budgets() {
  const [selectedBudget, setSelectedBudget] = useState(undefined);
  const [budgetToDelete, setBudgetToDelete] = useState(undefined);
  const {
    getBudgetsData,
    saveBudget,
    duplicateBudget,
    deleteBudget,
  } = useGlobalState();
  const budgets = useMemo(() => getBudgetsData(), [getBudgetsData]);

  return (
    <div className="Budgets">
      <BudgetsTable
        rows={budgets}
        onRowClick={(budgetId) =>
          setSelectedBudget(budgets.find((budget) => budget.id === budgetId))
        }
        onAddClick={() => setSelectedBudget({})}
        onDuplicateClick={(budgetId) => duplicateBudget(budgetId)}
        onDeleteClick={(budgetId) =>
          setBudgetToDelete(budgets.find((budget) => budget.id === budgetId))
        }
      />
      <BudgetsChart budgets={budgets} />
      {Boolean(selectedBudget) && (
        <BudgetModal
          open
          mode={
            selectedBudget && selectedBudget.id
              ? BudgetModalMode.Edit
              : BudgetModalMode.Add
          }
          data={selectedBudget}
          onSave={(savedBudget) => {
            saveBudget(savedBudget);
            setSelectedBudget(undefined);
          }}
          onCancel={() => setSelectedBudget(undefined)}
        />
      )}
      {Boolean(budgetToDelete) && (
        <ConfirmModal
          open
          danger
          title={`Delete budget`}
          message={`Are you sure you want to delete "${budgetToDelete.name}"?`}
          data={budgetToDelete}
          confirmLabel="Delete"
          onConfirm={() => {
            deleteBudget(budgetToDelete.id);
            setBudgetToDelete(undefined);
          }}
          onCancel={() => setBudgetToDelete(undefined)}
        />
      )}
    </div>
  );
}

export default memo(Budgets);
