import { Modal, TextArea } from "@carbon/react";
import React, { memo, useState } from "react";

import "./ExportImportDataModal.scss";

function ExportImportDataModal({ open, data: initialData, onSave, onClose }) {
  const [data, setData] = useState(JSON.stringify(initialData));

  return (
    <Modal
      open={open}
      modalHeading="Export / import data"
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      onRequestSubmit={() => onSave(JSON.parse(data))}
      onRequestClose={onClose}
      onSecondarySubmit={onClose}
    >
      <div className="ExportImportDataModalContent">
        <TextArea 
          rows={20}
          labelText="Your data is your responsibility!" 
          value={data}
          onChange={(event) => setData(event.target.value)}
        />
      </div>
    </Modal>
  );
}

export default memo(ExportImportDataModal);
