import React, { memo, useMemo } from 'react';

import './PropertyModal.scss';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { projectProperty } from '../../utils/projection';
import { formatCurrency } from '../../utils/format';
import { chartColors } from '../../utils/constants';
import { useGlobalState } from '../../hooks/useGlobalState';

import './PropertiesChart.scss';

function PropertiesChart({ properties }) {
  const { settings, isMonthlyProjection } = useGlobalState();

  const chartData = useMemo(() => {
    const data = [];
    (properties || []).forEach((property) => {
      const propertyProjection = projectProperty(
        property,
        settings.projectionLength,
      );

      propertyProjection.forEach((projection, index) => {
        if (isMonthlyProjection()) {
          data[projection.month - 1] = {
            ...(data[projection.month - 1] ? data[projection.month - 1] : {}),
            year: projection.year,
            month: projection.month,
            [property.name]: projection.value,
          };
        } else {
          data[projection.year - 1] = {
            ...(data[projection.year - 1] ? data[projection.year - 1] : {}),
            year: projection.year,
            [property.name]: projection.value,
          };
        }
      });
    });
    return data;
  }, [properties, settings.projectionLength, isMonthlyProjection]);

  return (
    <div className="LoansChart">
      <h4>
        {isMonthlyProjection() ? 'Month-to-month' : 'Year-on-year'} capital
        growth projections
      </h4>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          isAnimationActive={false}
          width={500}
          height={1000}
          data={chartData}
          margin={{
            top: 32,
            right: 16,
            left: 64,
            bottom: 16,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={isMonthlyProjection() ? 'month' : 'year'} />
          <YAxis interval="preserveStartEnd" tickFormatter={formatCurrency} />
          <Tooltip formatter={formatCurrency} />
          <Legend />
          {!chartData || chartData.length === 0
            ? null
            : Object.keys(chartData[0])
                .filter((key) => key !== 'month' && key !== 'year')
                .map((key, index) => (
                  <Line
                    key={index}
                    isAnimationActive={false}
                    type="monotone"
                    dataKey={key}
                    stroke={chartColors[index]}
                    name={key}
                    dot={null}
                  />
                ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default memo(PropertiesChart);
