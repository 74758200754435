import React, { memo, useMemo } from 'react';

import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  Button,
} from '@carbon/react';
import { FaCopy, FaTrash } from 'react-icons/fa';

import { formatCurrency, getSentimentColor } from '../../utils/format';
import { projectBudgets, projectBudgetRow } from '../../utils/projection';

import { BudgetType } from './BudgetModal';
import { useGlobalState } from '../../hooks/useGlobalState';
import InfoGroup from '../InfoGroup/InfoGroup';
import { PaymentFrequency } from '../../utils/constants';

const headers = [
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'budgetType',
    header: 'Type',
  },
  {
    key: 'frequency',
    header: 'Payment frequency',
  },
  {
    key: 'amount',
    header: 'Amount ($)',
    align: 'right',
  },
  {
    key: 'monthlyAmount',
    header: 'Monthly ($)',
    align: 'right',
  },
  {
    key: 'annualAmount',
    header: 'Annual ($)',
    align: 'right',
  },
];

function BudgetsTable({
  rows,
  onRowClick,
  onAddClick,
  onDuplicateClick,
  onDeleteClick,
}) {
  const { settings, isMonthlyProjection, yearIndex } = useGlobalState();
  const { projectionLength, inflation, incomeGrowth } = settings || {};
  const projections = useMemo(
    () => projectBudgets(rows, inflation, incomeGrowth, projectionLength),
    [rows, projectionLength, inflation, incomeGrowth],
  );

  const rowProjections = useMemo(
    () =>
      rows.map((row) =>
        projectBudgetRow(row, inflation, incomeGrowth, projectionLength),
      ),
    [rows, projectionLength, inflation, incomeGrowth],
  );

  const description = useMemo(() => {
    let totalIncome = 0;
    let totalExpenses = 0;
    projections.forEach((row, rowIndex) => {
      if (isMonthlyProjection()) {
        totalIncome += row.income;
        totalExpenses += row.expenses;
      } else {
        totalIncome += row.income * 12;
        totalExpenses += row.expenses * 12;
      }
    });

    let totalMonthlyIncome = 0;
    let totalMonthlyExpenses = 0;
    rows.forEach((row, rowIndex) => {
      const rowProjection = rowProjections[rowIndex][yearIndex];
      if (row.budgetType === BudgetType.Income) {
        totalMonthlyIncome += rowProjection.monthlyAmount;
      }
      if (row.budgetType === BudgetType.Expense) {
        totalMonthlyExpenses += rowProjection.monthlyAmount;
      }
    });

    let totalBalance = totalIncome - totalExpenses;
    let totalMonthlyBalance = totalMonthlyIncome - totalMonthlyExpenses;
    return (
      <InfoGroup
        data={[
          { label: 'Total income', value: formatCurrency(totalIncome) },
          {
            label: 'Total expenses',
            value: formatCurrency(totalExpenses),
          },
          {
            label: 'Total balance',
            value: (
              <span className={getSentimentColor(totalBalance)}>
                {formatCurrency(totalBalance)}
              </span>
            ),
          },
          {
            label: 'Monthly income',
            value: formatCurrency(totalMonthlyIncome),
          },
          {
            label: 'Monthly expenses',
            value: formatCurrency(totalMonthlyExpenses),
          },
          {
            label: 'Monthly balance',
            value: (
              <span className={getSentimentColor(totalMonthlyBalance)}>
                {formatCurrency(totalMonthlyBalance)}
              </span>
            ),
          },
        ]}
      />
    );
  }, [rows, projections, rowProjections, yearIndex, isMonthlyProjection]);

  const formattedRows = useMemo(
    () =>
      rows.map((row, rowIndex) => {
        const projection = rowProjections[rowIndex][yearIndex];
        let frequency = '';
        switch (row.frequency) {
          case PaymentFrequency.Weekly:
            frequency = 'Weekly';
            break;
          case PaymentFrequency.Fortnightly:
            frequency = 'Fortnightly';
            break;
          case PaymentFrequency.Quarterly:
            frequency = 'Quarterly';
            break;
          case PaymentFrequency.Annually:
            frequency = 'Annually';
            break;
          default:
          case PaymentFrequency.Monthly:
            frequency = 'Monthly';
            break;
        }
        return {
          ...row,
          budgetType:
            row.budgetType === BudgetType.Income ? (
              <div className="PositiveColor">Income</div>
            ) : (
              <div className="NegativeColor">Expense</div>
            ),
          frequency,
          amount: formatCurrency(projection.amount),
          monthlyAmount: formatCurrency(projection.monthlyAmount),
          annualAmount: formatCurrency(projection.annualAmount),
        };
      }),
    [rows, rowProjections, yearIndex],
  );

  function handleDuplicateRow(row, event) {
    event.preventDefault();
    event.stopPropagation();
    onDuplicateClick(row);
  }

  function handleDeleteRow(row, event) {
    event.preventDefault();
    event.stopPropagation();
    onDeleteClick(row);
  }

  return (
    <DataTable rows={formattedRows} headers={headers}>
      {({
        rows,
        headers,
        getTableProps,
        getHeaderProps,
        getRowProps,
        getToolbarProps,
        getTableContainerProps,
      }) => (
        <TableContainer
          title={`Personal budget summary for ${settings.projectionLength} ${
            settings.projectionLength <= 1 ? 'year' : 'years'
          }`}
          description={description}
          {...getTableContainerProps()}
        >
          <TableToolbar
            {...getToolbarProps()}
            aria-label="budgets table toolbar"
          >
            <TableToolbarContent>
              <Button onClick={onAddClick}>Add new</Button>
            </TableToolbarContent>
          </TableToolbar>
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader
                    {...getHeaderProps({ header })}
                    align={header.align}
                  >
                    {header.header}
                  </TableHeader>
                ))}
                <TableHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  {...getRowProps({ row })}
                  onClick={() => onRowClick(row.id)}
                >
                  {row.cells.map((cell, index) => (
                    <TableCell key={cell.id} align={headers[index].align}>
                      {cell.value}
                    </TableCell>
                  ))}
                  <TableCell
                    key="actions"
                    align={headers[headers.length - 1].align}
                  >
                    <div key="status" className="TableRowActions">
                      <Button
                        hasIconOnly
                        renderIcon={FaCopy}
                        kind="ghost"
                        iconDescription="Duplicate"
                        onClick={(event) => handleDuplicateRow(row.id, event)}
                      />
                      <Button
                        hasIconOnly
                        renderIcon={FaTrash}
                        kind="danger--ghost"
                        iconDescription="Delete"
                        onClick={(event) => handleDeleteRow(row.id, event)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  );
}

export default memo(BudgetsTable);
