import React, { memo, useMemo } from 'react';

import {
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  DataTable,
  TableContainer,
} from '@carbon/react';
import { formatCurrency } from '../../utils/format';
import {
  compoundPropertyMonthlyCostSumOf,
  projectBudgetRow,
  projectLoanRow,
  projectPropertyRow,
} from '../../utils/projection';

import { useGlobalState } from '../../hooks/useGlobalState';
import InfoGroup from '../InfoGroup/InfoGroup';
import { BudgetType } from '../Budgets/BudgetModal';
import { PropertyType } from '../Properties/PropertyModal';

export const CashflowType = {
  In: 'In',
  Out: 'Out',
};

const headers = [
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'type',
    header: 'Type',
    align: 'center',
  },
  {
    key: 'monthlyAmount',
    header: 'Monthly ($)',
    align: 'right',
  },
  {
    key: 'annualAmount',
    header: 'Annual ($)',
    align: 'right',
  },
  {
    key: 'totalAmount',
    header: 'Total ($)',
    align: 'right',
  },
];

function CashflowTable({ data }) {
  const { settings, yearIndex } = useGlobalState();
  const { projectionLength, inflation, incomeGrowth } = settings || {};
  const { loans, budgets, properties } = data;

  const budgetProjections = useMemo(
    () =>
      (budgets || []).map((row) =>
        projectBudgetRow(row, inflation, incomeGrowth, projectionLength)
      ),
    [budgets, projectionLength, inflation, incomeGrowth]
  );
  const propertyProjections = useMemo(
    () =>
      (properties || []).map((row) =>
        projectPropertyRow(row, projectionLength)
      ),
    [properties, projectionLength]
  );
  const loanProjections = useMemo(
    () => (loans || []).map((row) => projectLoanRow(row, projectionLength)),
    [loans, projectionLength]
  );

  const rows = useMemo(() => {
    const result = [];
    let totalPersonalIncome = 0;
    let totalPersonalExpenses = 0;
    let totalInvestmentPropertyIncome = 0;
    let totalInvestmentPropertyCost = 0;
    let totalOwnerOccupiedPropertyCost = 0;
    let totalLoanRepayment = 0;

    let totalMonthlyPersonalIncome = 0;
    let totalMonthlyPersonalExpenses = 0;
    let totalMonthlyInvestmentPropertyIncome = 0;
    let totalMonthlyInvestmentPropertyCost = 0;
    let totalMonthlyOwnerOccupiedPropertyCost = 0;
    let totalMonthlyLoanRepayment = 0;

    let totalAnnualPersonalIncome = 0;
    let totalAnnualPersonalExpenses = 0;
    let totalAnnualInvestmentPropertyIncome = 0;
    let totalAnnualInvestmentPropertyCost = 0;
    let totalAnnualOwnerOccupiedPropertyCost = 0;
    let totalAnnualLoanRepayment = 0;

    (budgets || []).forEach((budget, rowIndex) => {
      const projection = budgetProjections[rowIndex][yearIndex];
      if (budget.budgetType === BudgetType.Income) {
        totalMonthlyPersonalIncome += projection.monthlyAmount;
        totalAnnualPersonalIncome += projection.annualAmount;
        budgetProjections[rowIndex].forEach((projectionRow) => {
          totalPersonalIncome += projectionRow.annualAmount;
        });
      }
      if (budget.budgetType === BudgetType.Expense) {
        totalMonthlyPersonalExpenses += projection.monthlyAmount;
        totalAnnualPersonalExpenses += projection.annualAmount;
        budgetProjections[rowIndex].forEach((projectionRow) => {
          totalPersonalExpenses += projectionRow.annualAmount;
        });
      }
    });

    (properties || []).forEach((property, rowIndex) => {
      const projection = propertyProjections[rowIndex][yearIndex];
      const monthlyCosts = compoundPropertyMonthlyCostSumOf(
        property,
        settings.inflation,
        yearIndex
      );
      if (property.propertyType === PropertyType.Investment) {
        totalMonthlyInvestmentPropertyIncome += projection.rental;
        totalAnnualInvestmentPropertyIncome += projection.rental * 12;
        totalMonthlyInvestmentPropertyCost += monthlyCosts;
        totalAnnualInvestmentPropertyCost += monthlyCosts * 12;
        propertyProjections[rowIndex].forEach(
          (projectionRow, projectionIndex) => {
            const monthlyCostsByYearIndex = compoundPropertyMonthlyCostSumOf(
              property,
              settings.inflation,
              projectionIndex
            );
            totalInvestmentPropertyIncome += projectionRow.rental * 12;
            totalInvestmentPropertyCost += monthlyCostsByYearIndex * 12;
          }
        );
      }
      if (property.propertyType === PropertyType.OwnerOccupied) {
        totalMonthlyOwnerOccupiedPropertyCost += monthlyCosts;
        totalAnnualOwnerOccupiedPropertyCost += monthlyCosts * 12;
        propertyProjections[rowIndex].forEach((_, projectionIndex) => {
          const monthlyCostsByYearIndex = compoundPropertyMonthlyCostSumOf(
            property,
            settings.inflation,
            projectionIndex
          );
          totalOwnerOccupiedPropertyCost += monthlyCostsByYearIndex * 12;
        });
      }
    });

    (loans || []).forEach((_, rowIndex) => {
      const projection = loanProjections[rowIndex][yearIndex];
      totalMonthlyLoanRepayment += projection.repayment;
      totalAnnualLoanRepayment += projection.repayment * 12;
      loanProjections[rowIndex].forEach((projectionRow) => {
        totalLoanRepayment += projectionRow.repayment * 12;
      });
    });

    result.push({
      id: 'personalIncome',
      name: 'Personal income',
      type: CashflowType.In,
      monthlyAmount: totalMonthlyPersonalIncome,
      annualAmount: totalAnnualPersonalIncome,
      totalAmount: totalPersonalIncome,
    });

    result.push({
      id: 'investmentPropertyIncome',
      name: 'Investment property income',
      type: CashflowType.In,
      monthlyAmount: totalMonthlyInvestmentPropertyIncome,
      annualAmount: totalAnnualInvestmentPropertyIncome,
      totalAmount: totalInvestmentPropertyIncome,
    });

    result.push({
      id: 'personalExpenses',
      name: 'Personal expenses',
      type: CashflowType.Out,
      monthlyAmount: totalMonthlyPersonalExpenses,
      annualAmount: totalAnnualPersonalExpenses,
      totalAmount: totalPersonalExpenses,
    });

    result.push({
      id: 'investmentPropertyCosts',
      name: 'Investment property costs',
      type: CashflowType.Out,
      monthlyAmount: totalMonthlyInvestmentPropertyCost,
      annualAmount: totalAnnualInvestmentPropertyCost,
      totalAmount: totalInvestmentPropertyCost,
    });

    result.push({
      id: 'ownerOccupiedPropertyCosts',
      name: 'Owner-occupied property costs',
      type: CashflowType.Out,
      monthlyAmount: totalMonthlyOwnerOccupiedPropertyCost,
      annualAmount: totalAnnualOwnerOccupiedPropertyCost,
      totalAmount: totalOwnerOccupiedPropertyCost,
    });

    result.push({
      id: 'loanRepayments',
      name: 'Loan repayments',
      type: CashflowType.Out,
      monthlyAmount: totalMonthlyLoanRepayment,
      annualAmount: totalAnnualLoanRepayment,
      totalAmount: totalLoanRepayment,
    });

    return result;
  }, [
    loans,
    budgets,
    properties,
    budgetProjections,
    propertyProjections,
    loanProjections,
    yearIndex,
    settings.inflation,
  ]);

  const description = useMemo(() => {
    let totalMoneyIn = 0;
    let totalMoneyOut = 0;
    let totalMonthlyMoneyIn = 0;
    let totalMonthlyMoneyOut = 0;
    let totalMonthlyBalance = 0;

    rows.forEach((row) => {
      if (row.type === CashflowType.In) {
        totalMoneyIn += row.totalAmount;
        totalMonthlyMoneyIn += row.monthlyAmount;
        totalMonthlyBalance += row.monthlyAmount;
      }
      if (row.type === CashflowType.Out) {
        totalMoneyOut += row.totalAmount;
        totalMonthlyMoneyOut += row.monthlyAmount;
        totalMonthlyBalance -= row.monthlyAmount;
      }
    });

    return (
      <InfoGroup
        data={[
          { label: 'Total money in', value: formatCurrency(totalMoneyIn) },
          {
            label: 'Total money out',
            value: formatCurrency(totalMoneyOut),
          },
          {
            label: 'Monthly money in',
            value: formatCurrency(totalMonthlyMoneyIn),
          },
          {
            label: 'Monthly money out',
            value: formatCurrency(totalMonthlyMoneyOut),
          },
          {
            label: 'Monthly cashflow',
            value: (
              <span
                className={
                  totalMonthlyBalance > 0
                    ? 'PositiveColor'
                    : totalMonthlyBalance < 0
                    ? 'NegativeColor'
                    : ''
                }
              >
                {formatCurrency(totalMonthlyBalance)}
              </span>
            ),
          },
        ]}
      />
    );
  }, [rows]);

  const formattedRows = useMemo(
    () =>
      rows.map((row) => ({
        ...row,
        type: (
          <span
            className={
              row.type === CashflowType.In ? 'PositiveColor' : 'NegativeColor'
            }
          >
            {row.type}
          </span>
        ),
        monthlyAmount: formatCurrency(row.monthlyAmount),
        annualAmount: formatCurrency(row.annualAmount),
        totalAmount: formatCurrency(row.totalAmount),
      })),
    [rows]
  );

  return (
    <DataTable rows={formattedRows} headers={headers}>
      {({
        rows,
        headers,
        getTableProps,
        getHeaderProps,
        getRowProps,
        getTableContainerProps,
      }) => (
        <TableContainer
          title={`Cashflow summary for ${settings.projectionLength} ${
            settings.projectionLength <= 1 ? 'year' : 'years'
          }`}
          description={description}
          {...getTableContainerProps()}
        >
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader
                    {...getHeaderProps({ header })}
                    align={header.align}
                  >
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow {...getRowProps({ row })}>
                  {row.cells.map((cell, index) => (
                    <TableCell key={cell.id} align={headers[index].align}>
                      {cell.value}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  );
}

export default memo(CashflowTable);
