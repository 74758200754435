import React, { memo, useMemo } from 'react';

import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  Button,
} from '@carbon/react';
import { FaCopy, FaTrash } from 'react-icons/fa';

import {
  formatCurrency,
  formatPercentage,
  getSentimentColor,
} from '../../utils/format';
import { projectLoan, projectLoanRow } from '../../utils/projection';

import { LoanType } from './LoanModal';
import { useGlobalState } from '../../hooks/useGlobalState';
import InfoGroup from '../InfoGroup/InfoGroup';

const headers = [
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'loanType',
    header: 'Repayment type',
  },
  {
    key: 'amount',
    header: 'Amount ($)',
    align: 'right',
  },
  {
    key: 'offset',
    header: 'Offset ($)',
    align: 'right',
  },
  {
    key: 'interest',
    header: 'Interest (%)',
    align: 'right',
  },
  {
    key: 'repayment',
    header: 'Monthly repayment ($)',
    align: 'right',
  },
  {
    key: 'interestCharges',
    header: 'Monthly interest ($)',
    align: 'right',
  },
  {
    key: 'paydown',
    header: 'Monthly paydown ($)',
    align: 'right',
  },
];

function LoansTable({
  rows,
  onRowClick,
  onAddClick,
  onDuplicateClick,
  onDeleteClick,
}) {
  const { settings, isMonthlyProjection, yearIndex } = useGlobalState();
  const projections = useMemo(
    () => rows.map((row) => projectLoan(row, settings.projectionLength)),
    [rows, settings.projectionLength],
  );

  const rowProjections = useMemo(
    () => rows.map((row) => projectLoanRow(row, settings.projectionLength)),
    [rows, settings.projectionLength],
  );

  const description = useMemo(() => {
    let totalRepayment = 0;
    let totalInterestCharge = 0;

    projections.forEach((projection) => {
      if (isMonthlyProjection()) {
        projection.forEach((row) => {
          totalRepayment += row.repayment;
          totalInterestCharge += row.interestCharge;
        });
      } else {
        projection.forEach((row) => {
          totalRepayment += row.repayment * 12;
          totalInterestCharge += row.interestCharge;
        });
      }
    });

    let totalMonthlyRepayment = 0;
    let totalMonthlyInterestCharges = 0;
    let totalMonthlyPaydown = 0;
    rows.forEach((_, rowIndex) => {
      const rowProjection = rowProjections[rowIndex][yearIndex];
      totalMonthlyRepayment += rowProjection.repayment;
      totalMonthlyInterestCharges += rowProjection.interestCharge;
      totalMonthlyPaydown +=
        rowProjection.repayment - rowProjection.interestCharge;
    });

    const totalPaydown = Math.round(totalRepayment - totalInterestCharge);
    return (
      <InfoGroup
        data={[
          { label: 'Total repayment', value: formatCurrency(totalRepayment) },
          {
            label: 'Total interest charges',
            value: formatCurrency(totalInterestCharge),
          },
          {
            label: 'Total paydown',
            value: (
              <span className={getSentimentColor(totalPaydown)}>
                {formatCurrency(totalPaydown)}
              </span>
            ),
          },
          {
            label: 'Monthly repayment',
            value: formatCurrency(totalMonthlyRepayment),
          },
          {
            label: 'Monthly interest charges',
            value: formatCurrency(totalMonthlyInterestCharges),
          },
          {
            label: 'Monthly paydown',
            value: (
              <span className={getSentimentColor(totalMonthlyPaydown)}>
                {formatCurrency(totalMonthlyPaydown)}
              </span>
            ),
          },
        ]}
      />
    );
  }, [projections, isMonthlyProjection, rowProjections, rows, yearIndex]);

  const formattedRows = useMemo(
    () =>
      rows.map((row, index) => {
        const rowProjection = rowProjections[index][yearIndex];

        return {
          ...row,
          loanType: row.loanType === LoanType.PrincipalInterest ? 'P&I' : 'IO',
          amount: formatCurrency(row.amount),
          offset: formatCurrency(row.offset),
          interest: formatPercentage(row.interest),
          repayment: formatCurrency(rowProjection.repayment),
          interestCharges: formatCurrency(rowProjection.interestCharge),
          paydown: formatCurrency(
            rowProjection.repayment - rowProjection.interestCharge,
          ),
        };
      }),
    [rows, yearIndex, rowProjections],
  );

  function handleDuplicateRow(row, event) {
    event.preventDefault();
    event.stopPropagation();
    onDuplicateClick(row);
  }

  function handleDeleteRow(row, event) {
    event.preventDefault();
    event.stopPropagation();
    onDeleteClick(row);
  }

  return (
    <DataTable rows={formattedRows} headers={headers}>
      {({
        rows,
        headers,
        getTableProps,
        getHeaderProps,
        getRowProps,
        getToolbarProps,
        getTableContainerProps,
      }) => (
        <TableContainer
          title={`Loan summary for ${settings.projectionLength} ${
            settings.projectionLength <= 1 ? 'year' : 'years'
          }`}
          description={description}
          {...getTableContainerProps()}
        >
          <TableToolbar {...getToolbarProps()} aria-label="loans table toolbar">
            <TableToolbarContent>
              <Button onClick={onAddClick}>Add new</Button>
            </TableToolbarContent>
          </TableToolbar>
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader
                    {...getHeaderProps({ header })}
                    align={header.align}
                  >
                    {header.header}
                  </TableHeader>
                ))}
                <TableHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  {...getRowProps({ row })}
                  onClick={() => onRowClick(row.id)}
                >
                  {row.cells.map((cell, index) => (
                    <TableCell key={cell.id} align={headers[index].align}>
                      {cell.value}
                    </TableCell>
                  ))}
                  <TableCell
                    key="actions"
                    align={headers[headers.length - 1].align}
                  >
                    <div key="status" className="TableRowActions">
                      <Button
                        hasIconOnly
                        renderIcon={FaCopy}
                        kind="ghost"
                        iconDescription="Duplicate"
                        onClick={(event) => handleDuplicateRow(row.id, event)}
                      />
                      <Button
                        hasIconOnly
                        renderIcon={FaTrash}
                        kind="danger--ghost"
                        iconDescription="Delete"
                        onClick={(event) => handleDeleteRow(row.id, event)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  );
}

export default memo(LoansTable);
