import { PaymentFrequency } from './constants';

export function arraySumOf(array, attrName) {
  if (!array || array.length === 0) return 0;
  return Math.round(array.reduce((sum, v) => sum + +v[attrName], 0));
}

export function arrayAvgOf(array, attrName) {
  if (!array || array.length === 0) return 0;
  return Math.round(
    array.reduce((sum, v) => sum + +v[attrName], 0) / array.length,
  );
}

export function toMonthlyValue(value, frequency) {
  switch (frequency) {
    case PaymentFrequency.Weekly:
      return (value * 52) / 12;
    case PaymentFrequency.Fortnightly:
      return (value * 26) / 12;
    case PaymentFrequency.Quarterly:
      return (value * 4) / 12;
    case PaymentFrequency.Annually:
      return value / 12;
    default:
    case PaymentFrequency.Monthly:
      return value;
  }
}

export function toAnnualValue(value, frequency) {
  switch (frequency) {
    case PaymentFrequency.Weekly:
      return value * 52;
    case PaymentFrequency.Fortnightly:
      return value * 26;
    case PaymentFrequency.Monthly:
      return value * 12;
    case PaymentFrequency.Quarterly:
      return value * 4;
    default:
    case PaymentFrequency.Annually:
      return value;
  }
}

export function fromAnnualValue(value, frequency) {
  switch (frequency) {
    case PaymentFrequency.Weekly:
      return value / 52;
    case PaymentFrequency.Fortnightly:
      return value / 26;
    case PaymentFrequency.Monthly:
      return value / 12;
    case PaymentFrequency.Quarterly:
      return value / 4;
    default:
    case PaymentFrequency.Annually:
      return value;
  }
}

export function compound(value = 1, percent = 0, count = 1) {
  if (count <= 1) return value;

  let result = value;
  let factor = percent / 100;
  for (let i = 0; i < count; i++) {
    result += result * factor;
  }
  return result;
}
