import React, { memo, useMemo } from 'react';

import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { useGlobalState } from '../../hooks/useGlobalState';
import { projectCashflow } from '../../utils/projection';
import { formatCurrency } from '../../utils/format';
import { chartColors } from '../../utils/constants';

import './CashflowChart.scss';

function CashflowChart({ data }) {
  const { settings, isMonthlyProjection } = useGlobalState();
  const { projectionLength, inflation, incomeGrowth } = settings || {};

  const chartData = useMemo(() => {
    return projectCashflow(
      data,
      inflation,
      incomeGrowth,
      projectionLength,
      isMonthlyProjection,
    );
  }, [data, projectionLength, inflation, incomeGrowth, isMonthlyProjection]);

  return (
    <div className="BudgetsChart">
      <h4>
        {isMonthlyProjection() ? 'Month-to-month' : 'Year-on-year'} cashflow
        projection
      </h4>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          isAnimationActive={false}
          width={500}
          height={1000}
          data={chartData}
          margin={{
            top: 32,
            right: 16,
            left: 64,
            bottom: 16,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={isMonthlyProjection() ? 'month' : 'year'} />
          <YAxis interval="preserveStartEnd" tickFormatter={formatCurrency} />
          <Tooltip formatter={formatCurrency} />
          <Legend />
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="moneyIn"
            stroke={chartColors[4]}
            name="Money in"
            dot={null}
          />
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="moneyOut"
            stroke={chartColors[1]}
            name="Money out"
            dot={null}
          />
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="cashflow"
            stroke={chartColors[2]}
            name="Cashflow"
            dot={null}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default memo(CashflowChart);
