import { BudgetType } from '../components/Budgets/BudgetModal';
import { LoanType } from '../components/Loans/LoanModal';
import { PropertyType } from '../components/Properties/PropertyModal';
import { PaymentFrequency } from './constants';
import {
  compound,
  fromAnnualValue,
  toAnnualValue,
  toMonthlyValue,
} from './math';

const MAX_TERMS = 35;
const MONTHS_IN_YEAR = 12;

export function calculateInterestCharge(amount, offset, interest) {
  const monthlyInterest = interest / MONTHS_IN_YEAR / 100;
  return Math.ceil(Math.max(0, amount - offset) * monthlyInterest);
}

export function projectLoan(
  { amount, offset, repayment, loanType, interest },
  terms = MAX_TERMS
) {
  const months = Math.min(terms, MAX_TERMS) * MONTHS_IN_YEAR;

  let projection = [];
  let balance = amount;
  let interestCharge = calculateInterestCharge(balance, offset, interest);
  let annualInterestCharge = 0;
  const repaymentAmt =
    loanType === LoanType.InterestOnly ? interestCharge : repayment;

  let lastYearInserted = -1;
  let currYear = 0;
  for (let i = 0; i < months; i++) {
    currYear = Math.floor(i / 12) + 1;
    interestCharge = calculateInterestCharge(balance, offset, interest);
    annualInterestCharge += interestCharge;
    if (terms <= 3) {
      projection.push({
        year: currYear,
        month: i + 1,
        interestCharge: Math.round(interestCharge),
        balance: Math.round(balance),
        repayment: repaymentAmt,
      });
      balance = balance - repaymentAmt + interestCharge;
    } else {
      if (i % 12 === 0) {
        projection.push({
          year: currYear,
          month: i + 1,
          interestCharge: Math.round(annualInterestCharge),
          balance: Math.round(balance),
          repayment: repaymentAmt,
        });
        annualInterestCharge = 0;
        lastYearInserted = currYear;
      }
      balance = balance - repaymentAmt + interestCharge;
      if (balance <= 0) {
        if (lastYearInserted !== currYear) {
          projection.push({
            year: currYear,
            month: i + 1,
            interestCharge: Math.round(annualInterestCharge),
            balance: Math.max(0, Math.round(balance)),
            repayment: 0,
          });
        }
        break;
      }
    }
  }

  return projection;
}

export function projectLoanRow(
  { amount, offset, repayment, loanType, interest },
  terms = MAX_TERMS
) {
  const months = Math.min(terms, MAX_TERMS) * MONTHS_IN_YEAR;

  let projection = [];
  let balance = amount;
  let interestCharge = calculateInterestCharge(balance, offset, interest);
  const repaymentAmt =
    loanType === LoanType.InterestOnly ? interestCharge : repayment;

  for (let i = 0; i < months; i++) {
    interestCharge = calculateInterestCharge(balance, offset, interest);
    if (i % 12 === 0) {
      projection.push({
        year: Math.floor(i / 12) + 1,
        interestCharge: Math.round(interestCharge),
        repayment: repaymentAmt,
        balance,
      });
    }
    balance = balance - repaymentAmt + interestCharge;
    if (balance < 0) {
      balance = 0;
    }
  }

  return projection;
}

export function projectProperty(
  { value, capitalGrowth, rental, rentalGrowth, propertyType },
  terms = MAX_TERMS
) {
  const months = Math.min(terms, MAX_TERMS) * MONTHS_IN_YEAR;
  const annualCapitalGrowth = capitalGrowth / 100;
  const annualRentalGrowth = rentalGrowth / 100;

  let projection = [];
  let valueAmt = value;
  let rentalAmt = propertyType === PropertyType.Investment ? rental : 0;
  let capitalGrowthAmt = 0;
  let rentalGrowthAmt = 0;

  let lastYearInserted = -1;
  let currYear = 0;
  for (let i = 0; i < months; i++) {
    currYear = Math.floor(i / 12) + 1;
    if (terms <= 3) {
      if (i % 12 === 0) {
        capitalGrowthAmt = valueAmt * annualCapitalGrowth;
        rentalGrowthAmt = rentalAmt * annualRentalGrowth;

        if (i / 12 >= 1) {
          valueAmt += capitalGrowthAmt;
          rentalAmt += rentalGrowthAmt;
        }
      }

      projection.push({
        year: currYear,
        month: i + 1,
        capitalGrowth: Math.round(capitalGrowthAmt),
        rentalGrowth: Math.round(rentalGrowthAmt),
        value: Math.round(valueAmt),
        rental: Math.round(rentalAmt),
      });
    } else {
      if (i % 12 === 0) {
        capitalGrowthAmt = valueAmt * annualCapitalGrowth;
        rentalGrowthAmt = rentalAmt * annualRentalGrowth;

        projection.push({
          year: currYear,
          month: i + 1,
          capitalGrowth: Math.round(capitalGrowthAmt),
          rentalGrowth: Math.round(rentalGrowthAmt),
          value: Math.round(valueAmt),
          rental: Math.round(rentalAmt),
        });

        valueAmt += capitalGrowthAmt;
        rentalAmt += rentalGrowthAmt;
        lastYearInserted = currYear;
      }
      if (i >= months) {
        if (lastYearInserted !== currYear) {
          projection.push({
            year: currYear,
            month: i + 1,
            capitalGrowth: Math.round(capitalGrowthAmt),
            rentalGrowth: Math.round(rentalGrowthAmt),
            value: Math.round(valueAmt),
            rental: Math.round(rentalAmt),
          });
          lastYearInserted = currYear;
        }
      }
    }
  }

  return projection;
}

export function projectPropertyRow(
  { value, capitalGrowth, rental, rentalGrowth, propertyType },
  terms = MAX_TERMS
) {
  const annualCapitalGrowth = capitalGrowth / 100;
  const annualRentalGrowth = rentalGrowth / 100;

  let projection = [];
  let valueAmt = value;
  let rentalAmt = propertyType === PropertyType.Investment ? rental : 0;
  let capitalGrowthAmt = 0;
  let rentalGrowthAmt = 0;

  for (let i = 0; i < terms; i++) {
    capitalGrowthAmt = valueAmt * annualCapitalGrowth;
    rentalGrowthAmt = rentalAmt * annualRentalGrowth;

    projection.push({
      year: Math.floor(i / 12) + 1,
      capitalGrowth: Math.round(capitalGrowthAmt),
      rentalGrowth: Math.round(rentalGrowthAmt),
      value: Math.round(valueAmt),
      rental: Math.round(rentalAmt),
    });

    valueAmt += capitalGrowthAmt;
    rentalAmt += rentalGrowthAmt;
  }

  return projection;
}

export function projectBudgets(
  budgets,
  inflation,
  incomeGrowth,
  terms = MAX_TERMS
) {
  const months = Math.min(terms, MAX_TERMS) * MONTHS_IN_YEAR;

  let projection = [];
  let balance = 0;
  let income = 0;
  let expenses = 0;
  let monthlyAmount;
  let inflationFactor = 1;
  let incomeFactor = 1;

  let currYear = 0;
  let budget;
  for (let i = 0; i < months; i++) {
    currYear = Math.floor(i / 12) + 1;
    income = 0;
    expenses = 0;

    for (let j = 0; j < budgets.length; j++) {
      budget = budgets[j];
      monthlyAmount = toMonthlyValue(budget.amount, budget.frequency);
      if (budget.budgetType === BudgetType.Income) {
        balance += monthlyAmount * incomeFactor;
        income += monthlyAmount * incomeFactor;
      } else {
        balance -= monthlyAmount * inflationFactor;
        expenses += monthlyAmount * inflationFactor;
      }
    }

    if (terms <= 3) {
      projection.push({
        year: currYear,
        month: i + 1,
        balance: Math.round(balance),
        income,
        expenses,
      });
    } else {
      if (i % 12 === 0) {
        projection.push({
          year: currYear,
          month: i + 1,
          balance: Math.round(balance),
          income,
          expenses,
        });
      }
    }

    if (i % 12 === 0) {
      inflationFactor += inflation / 100;
      incomeFactor += incomeGrowth / 100;
    }
  }

  return projection;
}

export function projectBudgetRow(
  budget,
  inflation,
  incomeGrowth,
  terms = MAX_TERMS
) {
  let projection = [];
  let annualAmount;
  let inflationFactor = 1;
  let incomeFactor = 1;

  for (let i = 0; i < terms; i++) {
    annualAmount = toAnnualValue(budget.amount, budget.frequency);

    if (budget.budgetType === BudgetType.Income) {
      annualAmount *= incomeFactor;
    } else {
      annualAmount *= inflationFactor;
    }

    projection.push({
      year: i,
      amount: Math.round(fromAnnualValue(annualAmount, budget.frequency)),
      monthlyAmount: Math.round(annualAmount / 12),
      annualAmount: Math.round(annualAmount),
    });

    inflationFactor += inflation / 100;
    incomeFactor += incomeGrowth / 100;
  }

  return projection;
}

export function projectCashflow(
  { budgets, loans, properties },
  inflation,
  incomeGrowth,
  terms = MAX_TERMS
) {
  const budgetsProjection = projectBudgets(
    budgets || [],
    inflation,
    incomeGrowth,
    terms
  );
  const loanProjections = (loans || []).map((loan) => projectLoan(loan, terms));
  const propertyProjections = (properties || []).map((property) =>
    projectProperty(property, terms)
  );

  const result = [];
  console.log(inflation, incomeGrowth, budgetsProjection);

  for (let yearIndex = 0; yearIndex < terms; yearIndex++) {
    let moneyIn = 0;
    let moneyOut = 0;

    const budget = budgetsProjection[yearIndex];
    moneyIn += budget.income * 12;
    moneyOut += budget.expenses * 12;

    (loans || []).forEach((_, loanIndex) => {
      if (loanIndex < loanProjections.length) {
        const projection = loanProjections[loanIndex][yearIndex];
        if (projection) {
          moneyOut += projection.repayment * 12;
        }
      }
    });

    (properties || []).forEach((property, propertyIndex) => {
      if (propertyIndex < propertyProjections.length) {
        const projection = propertyProjections[propertyIndex][yearIndex];
        if (projection && property.propertyType === PropertyType.Investment) {
          moneyIn += projection.rental * 52;
        }
      }
      moneyOut += compoundPropertyMonthlyCostSumOf(
        property,
        inflation,
        yearIndex
      );
    });

    result.push({
      year: yearIndex + 1,
      month: budget.month,
      moneyIn,
      moneyOut,
      cashflow: moneyIn - moneyOut,
    });
  }

  return result;
}

export function propertyMonthlyCostSumOf(property) {
  if (!property || !property.costs || property.costs.length === 0) return 0;
  return Math.round(
    property.costs.reduce((sum, row) => {
      switch (row.frequency) {
        case PaymentFrequency.Weekly:
          return sum + (+row.amount * 52) / 12;

        case PaymentFrequency.Quarterly:
          return sum + +row.amount / 3;

        case PaymentFrequency.Annually:
          return sum + +row.amount / 12;

        case PaymentFrequency.Monthly:
        default:
          return sum + +row.amount;
      }
    }, 0)
  );
}

export function compoundPropertyMonthlyCostSumOf(
  property,
  inflation,
  yearIndex
) {
  return Math.round(
    compound(propertyMonthlyCostSumOf(property), inflation, yearIndex)
  );
}
