import React, { memo, useCallback, useMemo, useState } from 'react';

import PropertiesChart from './PropertiesChart';
import PropertyModal, { PropertyModalMode } from './PropertyModal';
import PropertiesTable from './PropertiesTable';

import './Properties.scss';
import PropertyCostsModal from './PropertyCostsModal';
import { useGlobalState } from '../../hooks/useGlobalState';
import ConfirmModal from '../ConfirmModal';

function Properties() {
  const [selectedProperty, setSelectedProperty] = useState(undefined);
  const [propertyToDelete, setPropertyToDelete] = useState(undefined);
  const [selectedPropertyCosts, setSelectedPropertyCosts] = useState(undefined);
  const {
    getPropertiesData,
    saveProperty,
    savePropertyCosts,
    duplicateProperty,
    deleteProperty,
  } = useGlobalState();
  const properties = useMemo(() => getPropertiesData(), [getPropertiesData]);

  const handleRowCostClick = useCallback(
    (propertyId) => {
      setSelectedPropertyCosts(
        properties.find((property) => property.id === propertyId),
      );
    },
    [properties],
  );

  return (
    <div className="Properties">
      <PropertiesTable
        rows={properties}
        onRowClick={(propertyId) =>
          setSelectedProperty(
            properties.find((property) => property.id === propertyId),
          )
        }
        onAddClick={() => setSelectedProperty({})}
        onDuplicateClick={(propertyId) => duplicateProperty(propertyId)}
        onDeleteClick={(propertyId) =>
          setPropertyToDelete(
            properties.find((property) => property.id === propertyId),
          )
        }
        onRowCostClick={handleRowCostClick}
        onRowCostsClick={handleRowCostClick}
      />
      <PropertiesChart properties={properties} />
      {Boolean(selectedProperty) && (
        <PropertyModal
          open
          mode={
            selectedProperty && selectedProperty.id
              ? PropertyModalMode.Edit
              : PropertyModalMode.Add
          }
          data={selectedProperty}
          onSave={(property) => {
            saveProperty(property);
            setSelectedProperty(undefined);
          }}
          onCancel={() => setSelectedProperty(undefined)}
        />
      )}
      {Boolean(selectedPropertyCosts) && (
        <PropertyCostsModal
          open
          data={selectedPropertyCosts.costs}
          onSave={(costs) => {
            savePropertyCosts(selectedPropertyCosts, costs);
            setSelectedPropertyCosts(undefined);
          }}
          onCancel={() => setSelectedPropertyCosts(undefined)}
        />
      )}
      {Boolean(propertyToDelete) && (
        <ConfirmModal
          open
          danger
          title={`Delete property`}
          message={`Are you sure you want to delete "${propertyToDelete.name}"?`}
          data={propertyToDelete}
          confirmLabel="Delete"
          onConfirm={() => {
            deleteProperty(propertyToDelete.id);
            setPropertyToDelete(undefined);
          }}
          onCancel={() => setPropertyToDelete(undefined)}
        />
      )}
    </div>
  );
}

export default memo(Properties);
