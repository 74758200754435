import React, { memo, useMemo, useState } from 'react';
import {
  Modal,
  NumberInput,
  RadioButton,
  RadioButtonGroup,
  TextInput,
} from '@carbon/react';
import { v4 as uuid } from 'uuid';

import './LoanModal.scss';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { calculateInterestCharge, projectLoan } from '../../utils/projection';
import { getEventTargetAttr } from '../../utils/events';
import { formatCurrency } from '../../utils/format';

export const LoanModalMode = {
  Add: 'add',
  Edit: 'edit',
};

export const LoanType = {
  PrincipalInterest: 'pni',
  InterestOnly: 'io',
};

function LoanModal({
  data: initialData,
  mode = LoanModalMode.Add,
  open,
  onSave,
  onCancel,
}) {
  const [data, setData] = useState(
    mode === LoanModalMode.Add
      ? {
          id: uuid(),
          name: '',
          amount: 500000,
          offset: 0,
          interest: 6.5,
          repayment: calculateInterestCharge(500000, 0, 6.5),
          loanType: LoanType.PrincipalInterest,
        }
      : { ...initialData }
  );

  const modalHeading = useMemo(() => {
    switch (mode) {
      case LoanModalMode.Add:
        return 'Add new loan';
      case LoanModalMode.Edit:
        return 'Edit loan';
      default:
        return '';
    }
  }, [mode]);

  const loanTypeOptions = useMemo(
    () => [
      { id: LoanType.PrincipalInterest, text: 'P&I' },
      { id: LoanType.InterestOnly, text: 'Interest Only' },
    ],
    []
  );

  const chartData = useMemo(() => projectLoan(data), [data]);

  const isValid = useMemo(() => {
    if (!Boolean(data.name.trim())) return false;
    if (Number.isNaN(Number.parseFloat(data.amount))) return false;
    if (Number.isNaN(Number.parseFloat(data.offset))) return false;
    if (Number.isNaN(Number.parseFloat(data.interest))) return false;
    if (Number.isNaN(Number.parseFloat(data.repayment))) return false;
    return true;
  }, [data.name, data.amount, data.offset, data.interest, data.repayment]);

  const minimumRepayment = useMemo(
    () => calculateInterestCharge(data.amount, data.offset, data.interest),
    [data.amount, data.offset, data.interest]
  );

  function handleTextInputChange(event) {
    setData({
      ...data,
      [getEventTargetAttr(event, 'id')]: getEventTargetAttr(event, 'value'),
    });
  }

  function handleNumberInputChange(event) {
    setData({
      ...data,
      [getEventTargetAttr(event, 'id')]: +(
        getEventTargetAttr(event, 'value') || 0
      ),
    });
  }

  return (
    <Modal
      open={open}
      modalHeading={modalHeading}
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      primaryButtonDisabled={!isValid}
      onRequestSubmit={() => onSave(data)}
      onRequestClose={onCancel}
      onSecondarySubmit={onCancel}
    >
      <div className="LoanModalContent">
        <TextInput
          id="name"
          autoFocus
          labelText="Name"
          defaultValue={data.name}
          onChange={handleTextInputChange}
        />
        <RadioButtonGroup
          id="loanType"
          name="loanType"
          legendText="Repayment type"
          defaultSelected={data.loanType}
          onChange={(value, name) => {
            setData({
              ...data,
              [name]: value,
            });
          }}
        >
          {loanTypeOptions.map((o) => (
            <RadioButton key={o.id} value={o.id} id={o.id} labelText={o.text} />
          ))}
        </RadioButtonGroup>
        <div className="ModalContent_TwoColumn">
          <NumberInput
            id="amount"
            label="Loan amount ($)"
            defaultValue={data.amount}
            onChange={handleNumberInputChange}
            hideSteppers
          />
          <NumberInput
            id="offset"
            label="Offset amount ($)"
            defaultValue={data.offset}
            onChange={handleNumberInputChange}
            hideSteppers
          />
        </div>
        <div className="ModalContent_TwoColumn">
          <NumberInput
            id="interest"
            label="Loan interest (%)"
            defaultValue={data.interest}
            value={data.interest}
            onChange={(event) => {
              console.log(
                Math.round((getEventTargetAttr(event, 'value') || 0) * 100) /
                  100
              );
              setData({
                ...data,
                [getEventTargetAttr(event, 'id')]:
                  Math.round((getEventTargetAttr(event, 'value') || 0) * 100) /
                  100,
              });
            }}
            step={0.1}
          />
          <NumberInput
            id="repayment"
            label="Repayment amount ($)"
            defaultValue={data.repayment}
            value={
              data.loanType === LoanType.InterestOnly
                ? minimumRepayment
                : data.repayment
            }
            readOnly={data.loanType === LoanType.InterestOnly}
            onChange={handleNumberInputChange}
            hideSteppers
          />
        </div>
        <div className="LoanModalContent_Chart">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={chartData}
              margin={{
                top: 24,
                right: 16,
                left: 64,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis
                interval="preserveStartEnd"
                tickFormatter={formatCurrency}
              />
              <Legend />
              <Line
                isAnimationActive={false}
                type="monotone"
                dataKey="balance"
                stroke="#8884d8"
                name="Balance"
                dot={null}
              />
              <Line
                isAnimationActive={false}
                type="monotone"
                dataKey="interestCharge"
                stroke="#ca829d"
                name="Interest charges"
                dot={null}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Modal>
  );
}

export default memo(LoanModal);
