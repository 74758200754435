import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { v4 as uuid } from 'uuid';

const GlobalStateContext = createContext({});

const defaultSettings = {
  projectionLength: 3,
  inflation: 6,
  salaryGrowth: 3,
};

export const MAX_PROJECTIONS = 5;

function useGlobalStateFn({ settings: initialSettings = defaultSettings }) {
  const [settings, setSettings] = useState(initialSettings || defaultSettings);
  const [isInit, setIsInit] = useState(false);
  const [yearIndex, setYearIndex] = useState(0);
  const [loansData, setLoansData] = useState([]);
  const [propertiesData, setPropertiesData] = useState([]);
  const [budgetsData, setBudgetsData] = useState([]);
  const [selectedProjectionIndex, setSelectedProjectionIndex] = useState(0);

  useEffect(() => {
    const initialLoansData = [];
    for (let i = 0; i < MAX_PROJECTIONS; i++) {
      const loanData = window.localStorage.getItem(`loans_${i}`);
      if (!Boolean(loanData)) break;
      initialLoansData.push(JSON.parse(loanData));
    }
    if (initialLoansData.length === 0) {
      initialLoansData.push([]);
    }
    setLoansData(initialLoansData);

    const initialPropertiesData = [];
    for (let i = 0; i < MAX_PROJECTIONS; i++) {
      const propertyData = window.localStorage.getItem(`properties_${i}`);
      if (!Boolean(propertyData)) break;
      initialPropertiesData.push(JSON.parse(propertyData));
    }
    if (initialLoansData.length === 0) {
      initialPropertiesData.push([]);
    }
    setPropertiesData(initialPropertiesData);

    const initialBudgetsData = [];
    for (let i = 0; i < MAX_PROJECTIONS; i++) {
      const budgetData = window.localStorage.getItem(`budgets_${i}`);
      if (!Boolean(budgetData)) break;
      initialBudgetsData.push(JSON.parse(budgetData));
    }
    if (initialBudgetsData.length === 0) {
      initialBudgetsData.push([]);
    }
    setBudgetsData(initialBudgetsData);
    setIsInit(true);
  }, []);

  const exportAllData = useCallback(() => {
    return {
      loansData,
      propertiesData,
      budgetsData,
    };
  }, [loansData, propertiesData, budgetsData]);

  const importAllData = useCallback(
    ({
      loansData: importedLoansData,
      propertiesData: importedPropertiesData,
      budgetsData: importedBudgetsData,
    }) => {
      try {
        setLoansData(importedLoansData);
        setPropertiesData(importedPropertiesData);
        setBudgetsData(importedBudgetsData);

        importedLoansData.forEach((data, index) => {
          window.localStorage.setItem(`loans_${index}`, JSON.stringify(data));
        });
        importedPropertiesData.forEach((data, index) => {
          window.localStorage.setItem(
            `properties_${index}`,
            JSON.stringify(data)
          );
        });
        importedBudgetsData.forEach((data, index) => {
          window.localStorage.setItem(`budgets_${index}`, JSON.stringify(data));
        });
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const getLoansData = useCallback(() => {
    return loansData[selectedProjectionIndex] || [];
  }, [loansData, selectedProjectionIndex]);

  const getPropertiesData = useCallback(() => {
    return propertiesData[selectedProjectionIndex] || [];
  }, [propertiesData, selectedProjectionIndex]);

  const getBudgetsData = useCallback(() => {
    return budgetsData[selectedProjectionIndex] || [];
  }, [budgetsData, selectedProjectionIndex]);

  const addProjection = useCallback(() => {
    const updatedLoansData = [...loansData, []];
    setLoansData(updatedLoansData);

    const updatedPropertiesData = [...propertiesData, []];
    setPropertiesData(updatedPropertiesData);

    const updatedBudgetsData = [...budgetsData, []];
    setBudgetsData(updatedBudgetsData);

    setSelectedProjectionIndex(updatedLoansData.length - 1);
  }, [budgetsData, propertiesData, loansData]);

  const duplicateSelectedProjection = useCallback(() => {
    setLoansData([...loansData, JSON.parse(JSON.stringify(getLoansData()))]);
    setPropertiesData([
      ...propertiesData,
      JSON.parse(JSON.stringify(getPropertiesData())),
    ]);
    setBudgetsData([
      ...budgetsData,
      JSON.parse(JSON.stringify(getBudgetsData())),
    ]);
    setSelectedProjectionIndex(loansData.length);
  }, [
    loansData,
    propertiesData,
    budgetsData,
    getLoansData,
    getPropertiesData,
    getBudgetsData,
  ]);

  const deleteSelectedProjection = useCallback(() => {
    const updatedLoansData = [...loansData];
    updatedLoansData.splice(selectedProjectionIndex, 1);
    setLoansData(updatedLoansData);

    const updatedPropertiesData = [...propertiesData];
    updatedPropertiesData.splice(selectedProjectionIndex, 1);
    setPropertiesData(updatedPropertiesData);

    const updatedBudgetsData = [...budgetsData];
    updatedBudgetsData.splice(selectedProjectionIndex, 1);
    setBudgetsData(updatedBudgetsData);

    if (selectedProjectionIndex >= updatedLoansData.length) {
      setSelectedProjectionIndex(updatedLoansData.length - 1);
    }
  }, [budgetsData, propertiesData, loansData, selectedProjectionIndex]);

  const saveSettings = useCallback(
    (data) => {
      setSettings(data);
      if (yearIndex >= settings.projectionLength - 1) {
        setYearIndex(settings.projectionLength - 1);
      }
      window.localStorage.setItem('settings', JSON.stringify(data));
    },
    [settings.projectionLength, yearIndex]
  );

  const isMonthlyProjection = useCallback(() => {
    return settings.projectionLength <= 3;
  }, [settings.projectionLength]);

  const saveYearIndex = useCallback(
    (value) =>
      setYearIndex(Math.max(0, Math.min(value, settings.projectionLength - 1))),
    [settings.projectionLength]
  );

  const saveLoansData = useCallback(
    (updatedLoans) => {
      const updatedLoansData = [...loansData];
      updatedLoansData.splice(selectedProjectionIndex, 1, updatedLoans);
      setLoansData(updatedLoansData);
      window.localStorage.setItem(
        `loans_${selectedProjectionIndex}`,
        JSON.stringify(updatedLoans)
      );
    },
    [selectedProjectionIndex, loansData]
  );

  const saveLoan = useCallback(
    (savedLoan, index) => {
      const loans = getLoansData();
      const loanIndex = loans.findIndex((l) => l.id === savedLoan.id);
      if (loanIndex >= 0) {
        const updatedLoans = [...loans];
        updatedLoans.splice(loanIndex, 1, savedLoan);
        saveLoansData(updatedLoans, index);
      } else {
        saveLoansData([...loans, savedLoan], index);
      }
    },
    [getLoansData, saveLoansData]
  );

  const duplicateLoan = useCallback(
    (loanId) => {
      const loans = getLoansData();
      const loan = loans.find((l) => l.id === loanId);
      saveLoansData([
        ...loans,
        {
          ...JSON.parse(JSON.stringify(loan)),
          id: uuid(),
        },
      ]);
    },
    [getLoansData, saveLoansData]
  );

  const deleteLoan = useCallback(
    (loanId) => {
      const loans = getLoansData();
      saveLoansData(loans.filter((loan) => loan.id !== loanId));
    },
    [getLoansData, saveLoansData]
  );

  const savePropertiesData = useCallback(
    (updatedProperties) => {
      const updatedPropertiesData = [...propertiesData];
      updatedPropertiesData.splice(
        selectedProjectionIndex,
        1,
        updatedProperties
      );
      setPropertiesData(updatedPropertiesData);
      window.localStorage.setItem(
        `properties_${selectedProjectionIndex}`,
        JSON.stringify(updatedProperties)
      );
    },
    [selectedProjectionIndex, propertiesData]
  );

  const saveProperty = useCallback(
    (savedProperty, index) => {
      const properties = getPropertiesData();
      const propertyIndex = properties.findIndex(
        (l) => l.id === savedProperty.id
      );
      if (propertyIndex >= 0) {
        const updatedProperties = [...properties];
        updatedProperties.splice(propertyIndex, 1, savedProperty);
        savePropertiesData(updatedProperties, index);
      } else {
        savePropertiesData([...properties, savedProperty], index);
      }
    },
    [getPropertiesData, savePropertiesData]
  );

  const savePropertyCosts = useCallback(
    (property, updatedCosts) => {
      const properties = getPropertiesData();
      const propertyIndex = properties.findIndex((l) => l.id === property.id);
      if (propertyIndex >= 0) {
        const savedProperty = {
          ...property,
          costs: updatedCosts,
        };
        const updatedProperties = [...properties];
        updatedProperties.splice(propertyIndex, 1, savedProperty);
        savePropertiesData(updatedProperties);
      }
    },
    [getPropertiesData, savePropertiesData]
  );

  const duplicateProperty = useCallback(
    (propertyId) => {
      const properties = getPropertiesData();
      const property = properties.find((l) => l.id === propertyId);
      savePropertiesData([
        ...properties,
        {
          ...JSON.parse(JSON.stringify(property)),
          id: uuid(),
        },
      ]);
    },
    [getPropertiesData, savePropertiesData]
  );

  const deleteProperty = useCallback(
    (propertyId) => {
      const properties = getPropertiesData();
      savePropertiesData(
        properties.filter((property) => property.id !== propertyId)
      );
    },
    [getPropertiesData, savePropertiesData]
  );

  const saveBudgetsData = useCallback(
    (updatedBudgets) => {
      const updatedBudgetsData = [...budgetsData];
      updatedBudgetsData.splice(selectedProjectionIndex, 1, updatedBudgets);
      setBudgetsData(updatedBudgetsData);
      window.localStorage.setItem(
        `budgets_${selectedProjectionIndex}`,
        JSON.stringify(updatedBudgets)
      );
    },
    [budgetsData, selectedProjectionIndex]
  );

  const saveBudget = useCallback(
    (savedBudget) => {
      const budgets = getBudgetsData();
      const budgetIndex = budgets.findIndex((l) => l.id === savedBudget.id);
      if (budgetIndex >= 0) {
        const updatedBudgets = [...budgets];
        updatedBudgets.splice(budgetIndex, 1, savedBudget);
        saveBudgetsData(updatedBudgets);
      } else {
        saveBudgetsData([...budgets, savedBudget]);
      }
    },
    [getBudgetsData, saveBudgetsData]
  );

  const duplicateBudget = useCallback(
    (budgetId) => {
      const budgets = getBudgetsData();
      const budget = budgets.find((l) => l.id === budgetId);
      saveBudgetsData([
        ...budgets,
        {
          ...JSON.parse(JSON.stringify(budget)),
          id: uuid(),
        },
      ]);
    },
    [getBudgetsData, saveBudgetsData]
  );

  const deleteBudget = useCallback(
    (budgetId) => {
      const budgets = getBudgetsData();
      saveBudgetsData(budgets.filter((budget) => budget.id !== budgetId));
    },
    [getBudgetsData, saveBudgetsData]
  );

  return {
    isInit,
    addProjection,
    deleteSelectedProjection,
    duplicateSelectedProjection,
    selectedProjectionIndex,
    setSelectedProjectionIndex,
    settings,
    saveSettings,
    isMonthlyProjection,
    yearIndex,
    saveYearIndex,
    allLoansData: loansData,
    getLoansData,
    saveLoan,
    duplicateLoan,
    deleteLoan,
    getPropertiesData,
    saveProperty,
    savePropertyCosts,
    duplicateProperty,
    deleteProperty,
    getBudgetsData,
    saveBudget,
    duplicateBudget,
    deleteBudget,
    exportAllData,
    importAllData,
  };
}

export function GlobalStateProvider({ children }) {
  const returnProps = useGlobalStateFn({
    settings: JSON.parse(
      window.localStorage.getItem('settings') || JSON.stringify(defaultSettings)
    ),
  });
  return (
    <GlobalStateContext.Provider value={returnProps}>
      {typeof children === 'function' ? children(returnProps) : children}
    </GlobalStateContext.Provider>
  );
}

export function useGlobalState() {
  return useContext(GlobalStateContext);
}
