import React, { memo } from 'react';

import './InfoGroup.scss';

function InfoGroup({ data }) {
  return (
    <span className="InfoGroup">
      {data.map(({ label, value }, index) => (
        <span key={index} className="InfoGroup_Item">
          <span className="InfoGroup_Item_Label">{label}</span>
          <span className="InfoGroup_Item_Value">{value}</span>
        </span>
      ))}
    </span>
  );
}

export default memo(InfoGroup);
