import React, { memo, useMemo } from 'react';

import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { useGlobalState } from '../../hooks/useGlobalState';
import { projectBudgets } from '../../utils/projection';
import { formatCurrency } from '../../utils/format';
import { chartColors } from '../../utils/constants';

import './BudgetsChart.scss';

function BudgetsChart({ budgets }) {
  const { settings, isMonthlyProjection } = useGlobalState();
  const { projectionLength, inflation, incomeGrowth } = settings || {};

  const chartData = useMemo(() => {
    const data = [];
    const budgetProjection = projectBudgets(
      budgets,
      inflation,
      incomeGrowth,
      projectionLength,
    );
    budgetProjection.forEach((projection) => {
      if (isMonthlyProjection()) {
        data[projection.month - 1] = {
          ...(data[projection.month - 1] ? data[projection.month - 1] : {}),
          year: projection.year,
          month: projection.month,
          balance: projection.balance,
        };
      } else {
        data[projection.year - 1] = {
          ...(data[projection.year - 1] ? data[projection.year - 1] : {}),
          year: projection.year,
          balance: projection.balance,
        };
      }
    });
    return data;
  }, [budgets, projectionLength, inflation, incomeGrowth, isMonthlyProjection]);

  return (
    <div className="BudgetsChart">
      <h4>
        {isMonthlyProjection() ? 'Month-to-month' : 'Year-on-year'} cashflow
        projection
      </h4>
      <ResponsiveContainer width="100%" height="100%" isAnimationActive={false}>
        <LineChart
          isAnimationActive={false}
          width={500}
          height={1000}
          data={chartData}
          margin={{
            top: 32,
            right: 16,
            left: 64,
            bottom: 16,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={isMonthlyProjection() ? 'month' : 'year'} />
          <YAxis interval="preserveStartEnd" tickFormatter={formatCurrency} />
          <Tooltip formatter={formatCurrency} />
          <Legend />
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="balance"
            stroke={chartColors[0]}
            name="Balance"
            dot={null}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default memo(BudgetsChart);
