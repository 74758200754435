export const PaymentFrequency = {
  Weekly: 'weekly',
  Fortnightly: 'fortnightly',
  Monthly: 'monthly',
  Quarterly: 'quarterly',
  Annually: 'annually',
};

export const chartColors = [
  'purple',
  'darkred',
  'darkcyan',
  'hotpink',
  'darkgreen',
  'brown',
  'blue',
  'darkkhaki',
  'darkmagenta',
  'darkorange',
];
