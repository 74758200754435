import React, { memo, useMemo, useState } from 'react';
import {
  Button,
  ButtonSet,
  FormLabel,
  Modal,
  NumberInput,
  RadioButton,
  RadioButtonGroup,
  TextInput,
} from '@carbon/react';
import { v4 as uuid } from 'uuid';

import './BudgetModal.scss';
import { PaymentFrequency } from '../../utils/constants';
import { getEventTargetAttr } from '../../utils/events';

export const BudgetModalMode = {
  Add: 'add',
  Edit: 'edit',
};

export const BudgetType = {
  Income: 'income',
  Expense: 'expense',
};

function BudgetModal({
  data: initialData,
  mode = BudgetModalMode.Add,
  open,
  onSave,
  onCancel,
}) {
  const [data, setData] = useState(
    mode === BudgetModalMode.Add
      ? {
          id: uuid(),
          name: '',
          amount: 0,
          frequency: PaymentFrequency.Monthly,
          budgetType: BudgetType.Expense,
        }
      : { ...initialData }
  );

  const modalHeading = useMemo(() => {
    switch (mode) {
      case BudgetModalMode.Add:
        return 'Add new budget item';
      case BudgetModalMode.Edit:
        return 'Edit budget item';
      default:
        return '';
    }
  }, [mode]);

  const budgetTypeOptions = useMemo(
    () => [
      { id: BudgetType.Income, text: 'Income' },
      { id: BudgetType.Expense, text: 'Expense' },
    ],
    []
  );

  const frequencyOptions = useMemo(
    () => [
      { id: PaymentFrequency.Weekly, text: 'Wk' },
      { id: PaymentFrequency.Fortnightly, text: 'Frt' },
      { id: PaymentFrequency.Monthly, text: 'Mth' },
      { id: PaymentFrequency.Quarterly, text: 'Qtr' },
      { id: PaymentFrequency.Annually, text: 'Yr' },
    ],
    []
  );

  const isValid = useMemo(() => {
    if (!Boolean(data.name.trim())) return false;
    if (Number.isNaN(Number.parseFloat(data.amount))) return false;
    return true;
  }, [data.name, data.amount]);

  function handleTextInputChange(event) {
    setData({
      ...data,
      [getEventTargetAttr(event, 'id')]: getEventTargetAttr(event, 'value'),
    });
  }

  function handleNumberInputChange(event) {
    setData({
      ...data,
      [getEventTargetAttr(event, 'id')]: +(
        getEventTargetAttr(event, 'value') || 0
      ),
    });
  }

  return (
    <Modal
      open={open}
      modalHeading={modalHeading}
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      primaryButtonDisabled={!isValid}
      onRequestSubmit={() => onSave(data)}
      onRequestClose={onCancel}
      onSecondarySubmit={onCancel}
    >
      <div className="BudgetModalContent">
        <TextInput
          id="name"
          autoFocus
          labelText="Name"
          defaultValue={data.name}
          onChange={handleTextInputChange}
        />
        <RadioButtonGroup
          id="budgetType"
          name="budgetType"
          legendText="Type"
          defaultSelected={data.budgetType}
          onChange={(value, name) => {
            setData({
              ...data,
              [name]: value,
            });
          }}
        >
          {budgetTypeOptions.map((o) => (
            <RadioButton key={o.id} value={o.id} id={o.id} labelText={o.text} />
          ))}
        </RadioButtonGroup>
        <div className="ModalContent_TwoColumn">
          <NumberInput
            id="amount"
            label="Amount ($)"
            defaultValue={data.amount}
            onChange={handleNumberInputChange}
            hideSteppers
          />
          <div className="BudgetModal_Frequency">
            <FormLabel>Payment frequency</FormLabel>
            <ButtonSet className="BudgetModal_FrequencyOptions">
              {frequencyOptions.map((option) => (
                <Button
                  key={option.id}
                  kind={data.frequency === option.id ? 'primary' : 'secondary'}
                  onClick={() =>
                    handleTextInputChange({
                      target: { id: 'frequency', value: option.id },
                    })
                  }
                >
                  {option.text}
                </Button>
              ))}
            </ButtonSet>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default memo(BudgetModal);
