import React, { memo, useMemo, useState } from 'react';
import {
  Modal,
  NumberInput,
  RadioButton,
  RadioButtonGroup,
  TextInput,
} from '@carbon/react';
import { v4 as uuid } from 'uuid';

import './PropertyModal.scss';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { projectProperty } from '../../utils/projection';
import { getEventTargetAttr } from '../../utils/events';
import { formatCurrency } from '../../utils/format';

export const PropertyModalMode = {
  Add: 'add',
  Edit: 'edit',
};

export const PropertyType = {
  OwnerOccupied: 'owner',
  Investment: 'investment',
};

function PropertyModal({
  data: initialData,
  mode = PropertyModalMode.Add,
  open,
  onSave,
  onCancel,
}) {
  const [data, setData] = useState(
    mode === PropertyModalMode.Add
      ? {
          id: uuid(),
          name: '',
          value: 500000,
          capitalGrowth: 8,
          rental: 500,
          rentalGrowth: 4,
          propertyType: PropertyType.OwnerOccupied,
        }
      : { ...initialData }
  );

  const modalHeading = useMemo(() => {
    switch (mode) {
      case PropertyModalMode.Add:
        return 'Add new property';
      case PropertyModalMode.Edit:
        return 'Edit property';
      default:
        return '';
    }
  }, [mode]);

  const PropertyTypeOptions = useMemo(
    () => [
      { id: PropertyType.OwnerOccupied, text: 'Owner occupied' },
      { id: PropertyType.Investment, text: 'Investment' },
    ],
    []
  );

  const chartData = useMemo(() => projectProperty(data), [data]);

  const isValid = useMemo(() => {
    if (!Boolean(data.name.trim())) return false;
    if (Number.isNaN(Number.parseFloat(data.value))) return false;
    if (Number.isNaN(Number.parseFloat(data.capitalGrowth))) return false;
    if (Number.isNaN(Number.parseFloat(data.rental))) return false;
    if (Number.isNaN(Number.parseFloat(data.rentalGrowth))) return false;
    return true;
  }, [
    data.name,
    data.value,
    data.capitalGrowth,
    data.rental,
    data.rentalGrowth,
  ]);

  function handleTextInputChange(event) {
    setData({
      ...data,
      [getEventTargetAttr(event, 'id')]: getEventTargetAttr(event, 'value'),
    });
  }

  function handleNumberInputChange(event) {
    setData({
      ...data,
      [getEventTargetAttr(event, 'id')]: +(
        getEventTargetAttr(event, 'value') || 0
      ),
    });
  }

  return (
    <Modal
      open={open}
      modalHeading={modalHeading}
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      primaryButtonDisabled={!isValid}
      onRequestSubmit={() => onSave(data)}
      onRequestClose={onCancel}
      onSecondarySubmit={onCancel}
    >
      <div className="PropertyModalContent">
        <TextInput
          id="name"
          autoFocus
          labelText="Property name"
          defaultValue={data.name}
          onChange={handleTextInputChange}
        />
        <RadioButtonGroup
          id="propertyType"
          name="propertyType"
          legendText="Property type"
          defaultSelected={data.propertyType}
          onChange={(value, name) => {
            setData({
              ...data,
              [name]: value,
            });
          }}
        >
          {PropertyTypeOptions.map((o) => (
            <RadioButton key={o.id} value={o.id} id={o.id} labelText={o.text} />
          ))}
        </RadioButtonGroup>
        <div className="ModalContent_TwoColumn">
          <NumberInput
            id="value"
            label="Purchase value ($)"
            defaultValue={data.value}
            onChange={handleNumberInputChange}
            hideSteppers
          />
          <NumberInput
            id="capitalGrowth"
            label="Capital growth (%)"
            defaultValue={data.capitalGrowth}
            value={data.capitalGrowth}
            onChange={(event) => {
              setData({
                ...data,
                [getEventTargetAttr(event, 'id')]:
                  Math.round((getEventTargetAttr(event, 'value') || 0) * 100) /
                  100,
              });
            }}
            step={0.1}
          />
        </div>
        <div className="ModalContent_TwoColumn">
          <NumberInput
            id="rental"
            label="Weekly rental ($)"
            defaultValue={data.rental}
            onChange={handleNumberInputChange}
            disabled={data.propertyType === PropertyType.OwnerOccupied}
            hideSteppers
          />
          <NumberInput
            id="rentalGrowth"
            label="Rental growth (%)"
            defaultValue={data.rentalGrowth}
            value={data.rentalGrowth}
            disabled={data.propertyType === PropertyType.OwnerOccupied}
            onChange={(event) => {
              setData({
                ...data,
                [getEventTargetAttr(event, 'id')]:
                  Math.round((getEventTargetAttr(event, 'value') || 0) * 100) /
                  100,
              });
            }}
            step={0.1}
          />
        </div>
        <div className="PropertyModalContent_Chart">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={200}
              data={chartData}
              margin={{
                top: 24,
                right: 16,
                left: 64,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis
                interval="preserveStartEnd"
                tickFormatter={formatCurrency}
              />
              <Legend />
              <Line
                isAnimationActive={false}
                type="monotone"
                dataKey="value"
                stroke="#8884d8"
                name="Property value"
                dot={null}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        {data.propertyType === PropertyType.Investment && (
          <div className="PropertyModalContent_Chart">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={200}
                data={chartData}
                margin={{
                  top: 24,
                  right: 16,
                  left: 64,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" />
                <YAxis
                  interval="preserveStartEnd"
                  tickFormatter={formatCurrency}
                />
                <Legend />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  dataKey="rental"
                  stroke="#ca829d"
                  name="Weekly rent"
                  dot={null}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default memo(PropertyModal);
